 import React, { useState, useEffect } from "react";
 import { useCallback } from 'react'
 import Box from "@mui/material/Box";
 import TodayProgressCard from "common/ProgressCard/TodayProgressCard";
 import PastDueCard from "common/ProgressCard/PastDueCard";
 import MonthOFDate from "common/ProgressCard/MonthToDate";
 import Order from 'common/models/order';
 import OrderTab from "common/OrderTabs";
 import Vendor from 'common/models/vendor';

 import { createTheme, ThemeProvider } from "@mui/material/styles";

 const theme = createTheme({
  palette: {
   primary: {
    main: "#1976D2",
   },
   secondary: {
    main: "#fff",
   },
   danger: {
    main: "#941A19",
   },
  },
 });
 
 function dateFormat(date) {
  if (date) {
   var formatedDateTime = new Date(date);
   if (formatedDateTime == "Invalid Date") {
    const s_date = date.split(' ');
    const t_day = s_date[0].split('-');
    if (s_date.length == 2 && t_day.length == 3) {
     const d = `${t_day[2]}-${t_day[0]}-${t_day[1]} ${s_date[1]}`
     formatedDateTime = new Date(d);
    }
    else {
     return null
    }
   }

   return formatedDateTime
    .toLocaleString()
    .split(",")
    .join(" ")
    .split(":00 ")
    .join(" ");
  }
 }


 function createData(order) {
  return {
   orderId: order?.orderid,
   partId: order?.partid,
   partStatus: order?.part_statusid,
   orderDate: order?.ordereddate,
   address: `${order?.propertyaddress}, ${order?.propertycity}, ${order?.propertystate}  ${order?.propertyzipcode}`,
   orderType: order?.order_type,
   dueDate: order?.due_date,
   partDueDate: order?.part_due_date,
   completeDate: order?.completed_date,
   fee: order?.fee,
   reviewDate: order?.reviewDate,
   reason: order?.reason, 
   timeInReview: order?.timeInReview,
   workflowlink: ((order?.conductor_workflow?.length > 0 && order?.conductor_workflow[0]?.link) ? order.conductor_workflow[0].link : null)
  };
 }

 const MainContent = () => {

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false)
  const [isDeclineGuidelines, setIsDeclineGuidelines] = useState(false)
  const [orderStatus, setOrderStatus] = useState([{
   available: null,
   inProgress: null,
   pastDue: null,
   completed: null,
   monthOfDate: null,
  }, ]);
  const [order, setOrder] = useState([{
   availableOrder: null,
   currentOrder: null,
   completedOrder: null,
   operationalReview: null
  }, ]);


  const api = (new Order);
  const apiVendor = (new Vendor);

  const handelGetOrders = useCallback(async () => {
   try {
    const result = await api.getOrders();
    if (!result) { setLoading(true) }
    const { status, orders } = result;
    if (status == "success" && orders?.length == 0) {
     setOrder({
      availableOrder: [],
      currentOrder: [],
      completedOrder: [],
     });
     setOrderStatus({
      available: 0,
      inProgress: 0,
      completed: 0,
      pastDue: 0,
      monthOfDate: 0,
     });
     setLoading(false);
     setResponse(true)
    }
    else if (status === "success") {
     let availableOrder =
      orders && orders?.length && orders?.filter((availableItem) =>
       availableItem?.part_statusid === 2).map((item) =>
       createData(item)
      );

     let inProgressOrder =
      orders && orders?.length && orders?.filter((availableItem) =>
       availableItem?.part_statusid === 3).map((item) =>
       createData(item)
      );
     let completedOrder =
      orders && orders?.length && orders?.filter((item) =>
       item?.part_statusid === 7).map((item) =>
       createData(item)
      );
     let operationalReview =
      orders && orders?.length && orders?.filter((item) =>
       item?.part_statusid === 35 && item?.part_status === "Rejected").map((item) =>
       createData(item)
      );
     let pastDueOrder = orders && orders?.length && orders?.filter(
      (item) => item?.part_statusid !== 7 &&
      item?.part_statusid === 3 ||
      (item?.part_statusid === 2 &&
       item?.due_date < new Date().toLocaleString())
     );
     let monthDateOrder = orders && orders?.length && orders?.filter(
      (item) => {
       if (item?.part_statusid === 7) {
        let currentMonth = new Date();
        currentMonth.setMonth(currentMonth.getMonth() - 1);
        let lastMonth;
        let months = new Date(item?.accepteddate);
        lastMonth = currentMonth < months;
        return lastMonth;
       }
      });
     setOrder({
      availableOrder: availableOrder,
      currentOrder: inProgressOrder,
      completedOrder: completedOrder,
      operationalReview: operationalReview
     });
     setOrderStatus({
      available: availableOrder?.length,
      inProgress: inProgressOrder?.length,
      completed: completedOrder?.length,
      pastDue: pastDueOrder?.length,
      monthOfDate: monthDateOrder?.length,
     });
     setLoading(false);
     setResponse(true)
    }
   }
   catch (err) {
    console.log(err)
    setLoading(false)
    setResponse(false)
   }
  }, [])

  const handleGetDeclineGuidelines = useCallback(async () => {
   try {
    const result = await apiVendor.getDeclineGuidelines();
    if (!result) { setLoading(true) }
    const { status, isDeclined } = result;
    if (status === "success") {
     setIsDeclineGuidelines(isDeclined);
    }
   }
   catch (err) {
    console.log(err)
   }

  }, [])


  useEffect(() => {
   handelGetOrders();
   handleGetDeclineGuidelines();

  }, []);

  const { pastDue, monthOfDate } = orderStatus;
  return (
   <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", margin: "0 auto" ,mb:3}}>
        <Box sx={{ width:{md:"100%",lg:"93%"}, display: "flex", justifyContent: "space-between", my: 1 }}>
            <TodayProgressCard status={response && orderStatus} />
            <PastDueCard status={response && pastDue} />
            <MonthOFDate status={response && monthOfDate} />
        </Box>
        <Box  sx={{ my: 3 }}>
   <OrderTab  order={response && order} handleRefreshOrders={handelGetOrders} declineGuidelines={isDeclineGuidelines} loading={loading} />
   </Box>
      </Box>
    </ThemeProvider>
  );
 };
 export default MainContent;
 