import * as React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Grid,
  Button,
  TextField,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddBoxIcon from "@mui/icons-material/AddBox";
import Dialog from '@mui/material/Dialog';
import {css} from "@mui/system";
import { IMaskInput } from 'react-imask';
import { useVendor } from "core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isEmail, isPhone } from 'utils'


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}, css`
 .employees-dialog-header{
        background: ${theme.palette.primary.main};
        position: relative;
        color: #fff;
        padding: 20px 40px;
        font-weight: 400;
        font-size: 20px;
        .MuiButtonBase-root{
          color: #ffffff5e;
          top: 15px;
        }
    }

    .MuiInputLabel-formControl {
      background-color: #ffffff;
    }
    .MuiButtonBase-root{
      top: 0px;
    }
    .rightM {
      margin-right: 6px;
      color: #005fd4;
      padding: 8px 11px;
    }
`));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EmployeesDialogs() {
  const { formValue, saveEmployee, setTabValue } = useVendor();
  const [open, setOpen] = React.useState(false);
  const [addEmployee, setAddEmployee] = React.useState({
    vendor_type: 6,
    certificates:[{}]
  })
  const [requriedFields, setRequriedFields] = React.useState(null)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const normalizeValue = (value) => {
    return value || "";
  };

   const handleInputFiledChange = (event, index) => {
     const { name, value } = event.target;
      setAddEmployee({
        ...addEmployee,
        [name]: value,
      });
  };

  const saveEmployeeFun = () => {
    var requried = {}
    if (!addEmployee.firstname) {
      requried.firstname = true
    }
    if (!addEmployee.mobile || !isPhone(addEmployee.mobile)) {
      requried.mobile = true
    }
    if (!addEmployee.title) {
      requried.title = true
    }
    if (!addEmployee.phone || !isPhone(addEmployee.phone)) {
      requried.phone = true
    }
    if (!addEmployee.email || !isEmail(addEmployee.email)) {
      requried.email = true
    }

    if (Object.keys(requried).length !== 0) {
      setRequriedFields(requried)
      return
    }

    const newItem = {
      ...addEmployee,
      parent_user: formValue?.userid
    }
    setAddEmployee({
      newItem
    });
    saveEmployee(newItem);
    setTabValue(0);
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="text"
        endIcon={<AddBoxIcon />}
        size="large"
        onClick={handleClickOpen}
        sx={{ float: "right", padding: "8px 11px", color: "#005fd4 !important" }}
      >
        Add Employee
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="employees-dialog-header"
        maxWidth={"md"}
        open={open}
      >
        <BootstrapDialogTitle className="employees-dialog-header" onClose={handleClose}>
          Add Employee
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
                Contact Information
              </Typography>

              <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="FIRST NAME"
                    name="firstname"
                    value={normalizeValue(addEmployee?.firstname)}
                    error={requriedFields?.firstname}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="LAST NAME"
                    name="lastname"
                    value={normalizeValue(addEmployee?.lastname)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="MOBILE"
                    name="mobile"
                    fullWidth
                    variant="outlined"
                    value={normalizeValue(addEmployee?.mobile)}
                    error={requriedFields?.mobile}
                    onChange={handleInputFiledChange}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="JOB TITLE"
                    name="title"
                    value={normalizeValue(addEmployee?.title)}
                    error={requriedFields?.title}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container spacing={1} rowSpacing={2}>
                    <Grid item xs={8} sm={8} md={8}>
                      <TextField
                        label="PHONE"
                        name="phone"
                        fullWidth
                        variant="outlined"
                        value={normalizeValue(addEmployee?.phone)}
                        error={requriedFields?.phone}
                        onChange={handleInputFiledChange}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        label="EXT"
                        name="phoneext"
                        value={normalizeValue(addEmployee?.phoneext)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        onChange={handleInputFiledChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="EMAIL"
                    name="email"
                    value={normalizeValue(addEmployee?.email)}
                    error={requriedFields?.email}
                    type="email"
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} sx={{textAlign: 'right'}}>
              <Button variant="contained" size="large" autoFocus onClick={() => saveEmployeeFun()} sx={{ marginTop: "10px" }}>
                Save changes
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
