import React from "react";
import {
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
} from "@mui/material";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useVendor } from "core";


export default function LicensureItem({ item, index, onUpdate}) {

  const { states } = useVendor();
  const [addEmployee, setAddEmployee] = React.useState({certificates:[{}]});
  const [emplooyItem, setEmplooyItem] = React.useState(item);
  const [licenseType, setLicenseType] = React.useState("");
  const [stateTwo, setStateTwo] = React.useState("");
  const [licenseObtained, setLicenseObtained] = React.useState("");

  const setLicenseTypeChange = (event) => {
      setLicenseType(event.target.value);
      const { name, value } = event.target;
      const newItem = {
        ...emplooyItem,
        [name]: value
      }
      setEmplooyItem(newItem);
      onUpdate(newItem);
  };


  const stateTwoChange = (event) => {
      setStateTwo(event.target.value);
      const { name, value } = event.target;
        const newItem = {
        ...emplooyItem,
        [name]: value
      }
      setEmplooyItem(newItem);
      onUpdate(newItem);
  };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const newItem = {
        ...emplooyItem,
        [name]: value
      }
      setEmplooyItem(newItem);
      onUpdate(newItem);
  };

  const licenseObtainedChange = (event) => {
      setLicenseObtained(event.target.value);
      const { name, value } = event.target;
        const newItem = {
        ...emplooyItem,
        [name]: value
      }
      setEmplooyItem(newItem);
      onUpdate(newItem);
  };

  const normalizeValue = (value) => {
      return value || "";
  };

  return (
    <Grid
      container
      spacing={1}
      rowSpacing={2}
      sx={{
        marginBottom: "20px",
        paddingBottom: "20px",
        borderBottom: "1px #f2f2f2 solid",
      }}
    >
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth>
            <InputLabel id="license_type-label">LICENSE TYPE</InputLabel>
            <Select
              labelId="license_type-label"
              id={`license_type_${index}`}
              label="LICENSE TYPE"
              name="license_type"
              value={licenseType || normalizeValue(emplooyItem?.license_type)}
              onChange={(e) => setLicenseTypeChange(e, index)}
              required
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="CS">Certificate of Standing</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth>
            <InputLabel id="license_state-label">STATE</InputLabel>
            <Select
              labelId="license_state-label"
              id={`license_state_${index}`}
              name="license_state"
              value={stateTwo || normalizeValue(emplooyItem?.company_state)}
              label="STATE"
              onChange={(e) => stateTwoChange(e, index)}
              required
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            id={`license_number_${index}`}
            name="license_number"
            value={normalizeValue(emplooyItem?.license_number)}
            error={emplooyItem?.required?.license_number}
            label="LICENSE NUMBER"
            variant="outlined"
            onChange={(e) => handleInputChange(e, index)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            id={`last_updated_${index}`}
            label="DATE OBTAINED"
            name="last_updated"
            type="date"
            value={normalizeValue(emplooyItem?.last_updated)}
            error={emplooyItem?.required?.last_updated}
            onChange={(e) => handleInputChange(e, index)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            id={`expiration_date_${index}`}
            label="EXPIRATION DATE"
            name="license_expiration"
            type="date"
            value={
              normalizeValue(emplooyItem?.license_expiration)
            }
            error={emplooyItem?.required?.license_expiration}
            onChange={(e) => handleInputChange(e, index)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl sx={{ width: "53%" }}>
            <InputLabel id="license_obtained-label">STATUS</InputLabel>
            <Select
              labelId="license_obtained-label"
              id={`license_obtained_${index}`}
              name="license_obtained"
              value={
                licenseObtained || normalizeValue(emplooyItem?.license_obtained)
              }
              label="STATUS"
              onChange={(e) => licenseObtainedChange(e, index)}
              required
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              <MenuItem value="Not Eligible">Not Eligible</MenuItem>
              <MenuItem value="Eligible">Eligible</MenuItem>
              <MenuItem value="Deceased">Deceased</MenuItem>
            </Select>
          </FormControl>

          <IconButton
            aria-label="download"
            color="primary"
            size="large"
            sx={{ float: "right" }}
          >
            <SimCardDownloadRoundedIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="error"
            size="large"
            sx={{ float: "right" }}
            onClick={() => {
              const allCertificates = [...addEmployee?.certificates];
              allCertificates.splice(index, 1);
              setAddEmployee({
                ...addEmployee,
                certificates: allCertificates,
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}