
import RequiresAuth from 'common/authentication/RequiresAuth';
import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import VendorChrome from 'pages/vendor';
import VendorRegisterPage from 'pages/vendor/register';

const SettingsSubPage = lazy(() => import('pages/vendor/settings'));

export default function vendorRoutes() {
  return (
    <Routes >
      <Route path="/" element={<RequiresAuth requires="VENDOR"><VendorChrome /></RequiresAuth>}>
        <Route path="settings" element={<SettingsSubPage />} />
      </Route>
      <Route path="/register" element={<VendorRegisterPage />} />
    </Routes>
  );
}

