import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import ConfirmDialog from "./ConfirmDialog";
import Order from '../models/order';
import { format } from 'date-fns'

const scroll = {
  "&::-webkit-scrollbar": {
    width: "7px",
    height: "80px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: " 5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1976D2",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  minWidth: "68%",
  bgcolor: "background.paper",
  border: 0,
  outline: 0,
  boxShadow: 24,
  p: 0,
};
const fontStyle = {
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontFamily: "Inter",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
};

const BasicModal = ({ open, handleClose, orderDetails,setIsAvailableOrderRefresh }) => {
  const [state, setState] = useState({
    scopeOfWork: false,
  });
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [declineAgree, setDeclineAgree] = useState(false);
  const [startUrl, setStartUrl] = useState(null)

  const dateFormat = (date) => {
    if (date) {
      var formatedDateTime = new Date(date);
      if (formatedDateTime == "Invalid Date") {
        const s_date = date.split(' ');
        const t_day = s_date[0].split('-');
        if (s_date.length == 2 && t_day.length == 3) {
          const d = `${t_day[2]}-${t_day[0]}-${t_day[1]} ${s_date[1]}`
          formatedDateTime = new Date(d);
        } else {
          return null
        }
      }
  
      return formatedDateTime
        .toLocaleString()
        .split(",")
        .join(" ")
        .split(":00 ")
        .join(" ");
    }

  };

  const orderDetail = [{
      item: "order type",
      value: orderDetails?.order_type,
    },
    {
      item: "property address",
      value: `${orderDetails?.propertyaddress}, ${orderDetails?.propertycity}, ${orderDetails?.propertystate} ${orderDetails?.propertyzipcode}`,
    },
    {
      item: "order date",
      value: dateFormat(orderDetails?.ordereddate)
    },
    {
      item: "due date",
      value: orderDetails?.part_due_date && format(new Date(orderDetails?.part_due_date), 'M/dd/yyyy') + " 5:00 PM"
    },
    {
      item: Number(orderDetails?.vendor_typeid) === 5 ? "" : "fee",
      value: Number(orderDetails?.vendor_typeid) === 5 ? "" : orderDetails?.fee,
    },
    {
      item: "scope of work",
      value: orderDetails?.vendor_instructions
    },
  ];
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    setIsAccepted(!isAccepted);
  };


  const api = (new Order);
  const partId = orderDetails?.partid

  const handleAcceptOrder = async () => {
    setStartUrl(orderDetails?.conductor_workflow[0].link);

    try {
      const result = await api.acceptOrder(partId);
      if (!result) { setLoading(true) }
      const { status } = result;
      if (status === "success") {
        setIsAvailableOrderRefresh(true)
        setIsAccepted(false);
        setResponse(true);
        setIsOrderAccepted(true);
        setLoading(false);
      }
    }
    catch (err) {
      console.log(err)
      setResponse(false);
      setLoading(false)
    }
    setTimeout(() => {
      setResponse(false);
    }, 5000);
  }

  const handleDeclineOrder = async () => {
    try {
      const result = await api.declineOrder(partId);
      if (!result) { setLoading(true) }
      const { status } = result;
      if (status === "success") {
        setResponse(true);
        setLoading(false);
      }
    }
    catch (err) {
      console.log(err)
      setResponse(false);
      setLoading(false)
    }
    setTimeout(() => {
      setResponse(false);
      handleClose(true);
    }, 5000);
  }

  useEffect(() => {
    if (declineAgree) {
      handleDeclineOrder();
    }
  }, [declineAgree]);

  const handleOpenConfimDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfimDialog = () => {
    setOpenConfirmDialog(false);
    setDeclineAgree(false);
  };
  const handleDeclineAgree = () => {
    setDeclineAgree(true);
    setOpenConfirmDialog(false);
  };
  const transitionEffect = {
    btnActive: {
      opacity: 1,
      transition: "all 1s ease-in-out",
    },
    btnInActive: {
      opacity: 0.3,
      transition: "all 1s ease-in-out",
    },
  };
  const { btnActive, btnInActive } = transitionEffect;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      onBackdropClick="false"
    >
      <Box sx={style}>
        <Box
          sx={{
            backgroundColor: "#005fd5",
            height: "120px",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            px: 5,
            py: 4,
            boxSizing: "border-box",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <ClearIcon sx={{ opacity: "0.7", fontSize: "1.8rem" }} />
          </span>

          <Typography
            variant="h5"
            component="div"
            sx={{
              "&.MuiTypography-root": {
                fontSize: "1.875rem",
                fontFamily: "Inter",
              },
            }}
          >
             {!isOrderAccepted ? "Accept Order" : "Start Order" }
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            component="div"
            sx={{ opacity: "0.8", fontSize: "0.875rem" }}
          >
           {!isOrderAccepted ? "Review order details, and accept order for completion" : "This order has already been accepted. Click the Start Order button below to begin working on this assignment." }
          </Typography>
        </Box>
          {!loading && response && (
          <Alert severity={declineAgree ? "error" : "success"}>
            {declineAgree ? "Your order has been declined" : "You have accepted the order, and may start by clicking the Start Order button below"}
          </Alert>
        )}
        <Box sx={{ px: 5 }}>
          <List sx={{ width: "100%", bgcolor: "background.paper", pt: 2 }}>
            {orderDetail &&
              orderDetail?.map((order,i) => (
                <div key={i}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      display: "flex",
                      px: 0,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      variant="body2"
                      display="block"
                      color="primary"
                      sx={{
                        minWidth: 200,
                        textTransform: "uppercase",
                        ...fontStyle,
                      }}
                    >
                      {order?.item}
                    </Typography>
                    <Typography
                      variant="body2"
                      display="block"
                      gutterBottom
                      sx={
                        order?.value?.length > 100
                          ? {
                              ...fontStyle,
                              overflowY: "scroll",
                              width: "100%",
                              py: 0.5,
                              pr: 1,
                              height: "110px",
                              ...scroll,
                            }
                          : {
                              maxHeight: "100px",
                              width: "100%",
                              textTransform: "capitalize",
                              ...fontStyle,
                            }
                      }
                    >
                        <span
                        dangerouslySetInnerHTML={{
                          __html:  order?.value ||  Number(orderDetails?.vendor_typeid) !== 5 ? order?.value : ''
                        }}
                      />
                    </Typography>
                  </ListItem>
                    {order?.item && <Divider component="li" />}
                </div>
                
              ))}
          </List>
        </Box>
        <Box
          sx={{
            px: 5,
            mb: 3,
            width: "90%",
            display: "flex",
            justifyContent: "center",
          }}
        >
        {!isOrderAccepted && (
          <FormControlLabel
            sx={{
              "&.MuiFormControlLabel-label": {
                fontSize: "0.875rem !important",
              }
            }}
            control={
              <Checkbox
                checked={state.scopeOfWork}
                onChange={handleChange}
                name="scopeOfWork"
                sx={{ "&.MuiTypography-root": { fontSize: "0.875rem !important",letterSpacing: '0.9px'}}}
                disabled={isOrderAccepted}
              />
            }
            label="I certify that I understand and accept the scope of work for the assigment"
          />
          )}
        </Box>
         <ConfirmDialog
          handleDeclineAgree={handleDeclineAgree}
          handleClose={handleCloseConfimDialog}
          open={openConfirmDialog}
        />
        <Box sx={{ px: 5, mb: 5, display: "flex", justifyContent: "end" }}>
          {!isOrderAccepted && (
            <Fade
            in={!declineAgree && !isOrderAccepted}
            timeout={isOrderAccepted || declineAgree ? 4000 : 400}
          >
            <Button
              variant="contained"
              size="small"
             sx={{  px: 2, py: 1, mx: 2, transition: "all 1s ease-in-out" }}
              color="error"
              onClick={handleOpenConfimDialog}
            >
              decline order
            </Button>
           </Fade>
          )}
          <Button variant="contained" size="small" 
            sx={
            { px: 2, py: 1},
              isAccepted
                ? btnActive
                : btnInActive
                ? isOrderAccepted
                  ? btnActive
                  : btnInActive
                : btnInActive
            }
            onClick={isAccepted ? handleAcceptOrder : () => handleClose(true)}
            target={isOrderAccepted ? "_blank": ""}
            href={isOrderAccepted ? startUrl : ""}
             >
              {isOrderAccepted ? (
              <Fade in={true} timeout={4000}>
                <span style={{padding:'4px auto'}}>start order</span>
              </Fade>
            ) : (
              <span>
                accept order
                {loading && (
                  <CircularProgress size={20} sx={{ px: 2, color: "white" }} />
                )}
              </span>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default BasicModal;
