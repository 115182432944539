import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import ConfirmDialog from "./ConfirmDialog";
import Order from '../models/order';

const scroll = {
  "&::-webkit-scrollbar": {
    width: "7px",
    height: "80px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: " 5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1976D2",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  minWidth: "68%",
  bgcolor: "background.paper",
  border: 0,
  outline: 0,
  boxShadow: 24,
  p: 0,
};
const fontStyle = {
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontFamily: "Inter",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    height: "30vh",
  },
};

const TermsCondition = ({ open, handleClose, termsDetailsResponse ,companyName}) => {
  const [state, setState] = useState({
    acceptTerms: false,
  });
  const [declineAgree, setDeclineAgree] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [readTerms, setReadTerms] = useState(true);
  const [loading, setLoading] = useState(false);
  const [guidelineResponse, setGuidelineResponse] = useState({
    message: "",
    guidelineStatus: null,
  });


  const { id, guidelinetext } = termsDetailsResponse;
  const api = (new Order);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    setIsAccepted(!isAccepted);
  };

  const handleAgreeGuidelines = async () => {
    const result = await api.acceptGuidelines(id);
    if (!result) { setLoading(true) }
    const { status, message } = result;
    try {
      if (status === "success") {
        setGuidelineResponse({
          message: 'Guidelines have been accepted',
          guidelineStatus: 'agree'
        });
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleDisagreeGuidelines = async () => {
    const result = await api.declineGuidelines(id);
    if (!result) { setLoading(true) }
    const { status, message } = result;
    try {
      if (status === "success") {
        setGuidelineResponse({
          message: message, 
          guidelineStatus: 'disagree'
        });
        setTimeout(() => {
          setDeclineAgree(true);
        }, 3000);
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  const handleScrollReadTermsCondition = () => {
    const termsCondition = document.getElementById("termsCondition");
    let scrollY = termsCondition.scrollTop;
    let scrollBottom =
      termsCondition.scrollHeight - termsCondition.clientHeight - 1;
    if (scrollY > scrollBottom || scrollY === scrollBottom) {
      setReadTerms(false);
    }
  };
  const transitionEffect = {
    btnActive: {
      opacity: 1,
      transition: "all 1s ease-in-out",
    },
  };
  const { btnActive } = transitionEffect;
  const { guidelineStatus, message } = guidelineResponse;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      onBackdropClick="false"
    >
      <Box sx={style}>
        <Box
          sx={{
            backgroundColor: "#005fd5",
            minHeight: "150px",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            px: { xs: 2, sm: 2, md: 4 },
            py: 2,
            boxSizing: "border-box",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <ClearIcon sx={{ opacity: "0.7", fontSize: "1.8rem" }} />
          </span>
          <Typography
            variant="h5"
            component="div"
            sx={{
              "&.MuiTypography-root": {
                fontSize: "1.875rem",
                fontFamily: "Inter",
              },
            }}
          >
            Terms & Conditions
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            component="div"
            sx={{ opacity: "0.8", fontSize: "0.875rem" }}
          >
            Please read carefully, as there may have been changes to our terms &
            conditions that apply to your services. <br />
            You are required to scroll to the end to accept these terms.
          </Typography>
        </Box>
          {message !=="" && (
          <Alert severity={guidelineStatus === "agree" ? "success" : "error"}>
           {message}
          </Alert>
        )}
        <Box sx={{ px: 5 }}>
          <List sx={{ width: "100%", bgcolor: "background.paper", pt: 2 }}>
            <div>
              <ListItem
                alignItems="flex-start"
                sx={{
                  display: "flex",
                  px: 0,
                  py: 0.5,
                }}
              >
                <Typography
                  variant="body2"
                  display="block"
                  gutterBottom
                  id="termsCondition"
                  onScroll={handleScrollReadTermsCondition}
                  sx={{
                    ...fontStyle,
                    overflowY: "scroll",
                    width: "100%",
                    py: 0.5,
                    pr: 1,
                    ...scroll,
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: guidelinetext !=="" ? guidelinetext : '',
                    }}
                  />
                </Typography>
              </ListItem>
              <Divider component="li" />
            </div>
          </List>
        </Box>
        <Box
          sx={{
            px: 5,
            mb: 3,
            width: "90%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            sx={{
              "&.MuiFormControlLabel-label": {
                fontSize: "0.875rem !important",
              },
              "&.MuiTypography-root": { fontSize: "0.875rem !important" },
            }}
            control={
              <Checkbox
                checked={state.acceptTerms}
                disabled={readTerms}
                onChange={handleChange}
                name="acceptTerms"
                sx={{ my: 2 }}
              />
            }
            label="I have read the Terms and Conditions"
          />
        </Box>
        <ConfirmDialog handleClose={handleClose} open={declineAgree} companyName={companyName}/>
        <Box sx={{ px: 5, mb: 5, display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            size="small"
            sx={{  px: 2, py: 1, mx: 2, transition: "all 1s ease-in-out" }}
            color="error"
            onClick={handleDisagreeGuidelines}
             disabled={!isAccepted || loading}
          >
            decline terms
          </Button>

          <Button
            variant="contained"
            size="small"
            sx={btnActive,{ px: 2, py: 1}}
            onClick={isAccepted ? handleAgreeGuidelines : "" }
            disabled={!isAccepted || loading}
          >
            accept terms
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default TermsCondition;
