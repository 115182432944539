import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ProgressBar from "../../ProgressBar";
import Divider from "@mui/material/Divider";

const PastDueCard = ({ status }) => {
  return (
    <Card sx={{ flexBasis: "20%" }}>
      <Typography
        variant="button"
        display="block"
        sx={{ px: 2, py: 1, fontWeight: 700  }}
      >
        past due
      </Typography>
      <Divider />
      <CardContent sx={{ display: "flex" ,padding:'16px 5px'}}>
        <ProgressBar
          orderNo={status}
          percent={status}
          orderStatus="past due orders"
          color={"#930e13"}
        />
      </CardContent>
    </Card>
  );
};

export default PastDueCard;
