
import { useState } from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import UserManagementModal from './UserManagementModal';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

function SidebarItem ({ to, caption, icon, clicked }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  if (clicked)
    return (
      <ListItem button selected={!!match} onClick={clicked}>
        <ListItemIcon sx={{ minWidth: 36, color:'primary' }}>{icon}</ListItemIcon>
        <ListItemText primary={caption} />
      </ListItem>
    );
  return (
    <ListItem button component={Link} to={to} selected={!!match}>
      <ListItemIcon sx={{ minWidth: 36 ,color:'#005fd4' }}>{icon}</ListItemIcon>
      <ListItemText primary={caption} sx={{ textTransform:'capitalize'}} />
    </ListItem>
  );
}

SidebarItem.propTypes = {
  to: PropTypes.string,
  caption: PropTypes.string,
  icon: PropTypes.element,
};

export default function SidebarContents () {
  const [showUserManagementModal, setUserManagementModal] = useState(false);

  return (
    <List sx={{display:'flex',justifyContent:'start',flexDirection:'column',height:'calc(100% - 56px)'}}>
      <Box>
        <SidebarItem to="/vendor/" icon={<ClearAllIcon  />} caption="pipelines" />
        <Box sx={{position: "absolute", bottom: "1em", left: 0, right: 0}}>
          <SidebarItem to="/vendor/" clicked={()=>setUserManagementModal(true)}  icon={<ManageAccountsIcon />} caption="User Management" />
        </Box>
      </Box>
      {showUserManagementModal && <UserManagementModal show={showUserManagementModal} setShow={setUserManagementModal}/>}
    </List>
  );
}
