import * as React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Grid,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddBoxIcon from "@mui/icons-material/AddBox";
import LockResetIcon from '@mui/icons-material/LockReset';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {css} from "@mui/system";
import { IMaskInput } from 'react-imask';
import { useVendor } from "core";
import useAuthentication from 'common/authentication';
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import PasswordStrengthBar from 'react-password-strength-bar';
import AbcIcon from '@mui/icons-material/Abc';
import PasswordIcon from '@mui/icons-material/Password';
import LicensureItem from "./licensureList";



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}, css`
 .employees-dialog-header{
        background: ${theme.palette.primary.main};
        position: relative;
        color: #fff;
        padding: 20px 40px;
        font-weight: 400;
        font-size: 20px;
        .MuiButtonBase-root{
          color: #ffffff5e;
          top: 15px;
        }
    }

    .MuiInputLabel-formControl {
      background-color: #ffffff;
    }
    .MuiButtonBase-root{
      top: 0px;
    }
    .rightM {
      margin-right: 6px;
      color: #005fd4;
      padding: 8px 11px;
    }
`));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function profileData() {
  const { states, vendorProfile, setVendorProfile, profileData, setProfileData , getVendorProfile , updateVendorProfile, uploadVendorProfileDocument} = useVendor();
  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [passwordScore, setPasswordScore] = React.useState(0);
  const [originalEmail , setOriginalEmail ] = React.useState(false);
  const [showChangePasswordPanel, setshowChangePasswordPanel] = React.useState(false);
  const [showPasswordFieldText , setshowPasswordFieldText ] = React.useState(false);
  
  const { checkUserExists } = useAuthentication();
  
  const [ errorfields , setErrorfields ] = React.useState({});

  const checkForm = async () => {
    
    setErrorfields({});
    
    let newErrorObject = {};
    
    if(showChangePasswordPanel === true && profileData.password !== undefined && profileData.password != "" && (profileData.password != profileData.confirmPassword || profileData.confirmPassword === undefined) )
    {
      newErrorObject.password = true;
      newErrorObject.confirmPassword = true;
    }

    if(profileData.email && !profileData.email.match(/.+\@.+\..+/))
    {
      // simple regex to see if its even close to a real email
      // prevents sending an empty string to backend
      newErrorObject.email = true;
    }
    else if (originalEmail && profileData.email && originalEmail != "" && originalEmail !== profileData.email)
    {
      let emailavailable = await checkUserExists(profileData.email);
      if(!emailavailable)
      {
        newErrorObject.email = true;
        newErrorObject.duplicateemail = true;
      }
    }


    setErrorfields(newErrorObject);
    
  }

  const handleClose = () => {
    setshowChangePasswordPanel(false);
    setVendorProfile(false);
  };

  const normalizeValue = (value) => {
    return value || "";
  };
  
   const handleInputFieldChange = (event, index) => {
     const { name, value } = event.target;
      setProfileData({
        ...profileData,
        [name]: value,
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePassword = (prop,event) => {
    
    setProfileData({ ...profileData, [prop]: event.target.value });

  };

  const onLicensureUpdate = (item, index) => {
    const updatedLicensure = profileData.certificates;
    profileData.certificates[index] = item;
    setProfileData({
      ...profileData,
      certificates: updatedLicensure,
    });
  }
  const onLicenseDelete = (index) => {
    
    profileData.certificates.splice(index,1);
    
    setProfileData({
      ...profileData,
      certificates: [...profileData?.certificates],
    });
    
  }

  const saveEmployeeFun = async () => {
    
    checkForm();
    
    var fields_in_error = Object.keys(errorfields).filter( (key) => {
      return errorfields[key] == true;
    });
    
    if (fields_in_error.length === 0 )
    {
    
      await updateVendorProfile(profileData);
    
      if(profileData.certificates.length > 0)
      {
        let certs_with_attachments = profileData.certificates.filter((cert) => {
          return cert.licensePDF && cert.licensePDF.name && cert.licensePDF.size > 10;
        });
        
        certs_with_attachments = certs_with_attachments.map( (cert) => {
          
          return {
            'document' : cert.licensePDF,
            documentType :'license',
            licensestate : cert.license_state,
          };
        });
        
        await uploadVendorProfileDocument(certs_with_attachments);
        
        profileData.certificates = profileData.certificates.map( (cert) => {
          cert.licensePDF = null;
          cert.license_pdf_exists = true;
          
          return cert;
        });
        
        setProfileData(profileData);

      }
      
      handleClose();
    }
  }


  const addLicensure = () => {
    return profileData?.certificates?.map((item, index) => {
      return (<LicensureItem key={index} item={item} index={index} onUpdate={(item) => onLicensureUpdate(item, index)} onDelete={() => onLicenseDelete(index) }  />);
    });
  };
  
  React.useEffect( () => {
    
    getVendorProfile();
    
  },[]);
  
  React.useEffect( () => {
    
    if(originalEmail === false && profileData.email)
    {
      setOriginalEmail(profileData.email);
    }

    checkForm();
    
  },[showChangePasswordPanel,profileData?.password,profileData?.confirmPassword,profileData.email]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="employees-dialog-header"
        maxWidth={maxWidth}
        open={vendorProfile}
      >
        <BootstrapDialogTitle className="employees-dialog-header" onClose={handleClose}>
          My Profile
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
                Contact Information
              </Typography>

              <Grid container spacing={2} rowSpacing={2}>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="FIRST NAME"
                    name="firstname"
                    value={normalizeValue(profileData?.firstname)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="LAST NAME"
                    name="lastname"
                    value={normalizeValue(profileData?.lastname)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFieldChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="EMAIL"
                    name="email"
                    value={normalizeValue(profileData?.email)}
                    type="email"
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFieldChange}
                    error={errorfields.email ? errorfields.email : false }
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="Firm / Company Name"
                    name="company_name"
                    value={normalizeValue(profileData?.company_name)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFieldChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                        label="PHONE"
                        name="phone"
                        fullWidth
                        variant="outlined"
                        value={normalizeValue(profileData?.phone)}
                        onChange={handleInputFieldChange}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                        label="PHONE EXT"
                        name="phoneext"
                        fullWidth
                        variant="outlined"
                        value={normalizeValue(profileData?.phoneext)}
                        onChange={handleInputFieldChange}
                      />
                </Grid>


                <Grid item xs={12} sm={12} md={4}>

                  <TextField
                    label="MOBILE"
                    name="mobile"
                    fullWidth
                    variant="outlined"
                    value={normalizeValue(profileData?.mobile)}
                    onChange={handleInputFieldChange}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />

                </Grid>

              </Grid>
            </Grid>
          </Grid>

          <Typography variant="h5" component="h5" sx={{ marginBottom: '10px', marginTop: '20px' }}>
              Licensure
            </Typography>

          {addLicensure()}

          <Grid container spacing={3} rowSpacing={2}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="text"
                endIcon={<AddBoxIcon />}
                size="large"
                color="primary"
                className="rightM"
                onClick={() => {
                  setProfileData({
                    ...profileData,
                    certificates: [...profileData?.certificates, {}],
                  });
                }}
              >
                Add LICENSE
              </Button>
            </Grid>
          </Grid>

      <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
        Security
      </Typography>
        { showChangePasswordPanel ? 
      <Grid container spacing={3} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              NEW PASSWORD
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPasswordFieldText ? "text" : "password"}
              value={( profileData.password ? profileData.password : '' )}
              onChange={(e) => handlePassword("password",e)}
              error={errorfields.password ? errorfields.password : false }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setshowPasswordFieldText( !(showPasswordFieldText) );
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordFieldText ? <AbcIcon /> : <PasswordIcon />}
                  </IconButton>
                </InputAdornment>
              }
              label="NEW PASSWORD"
            />
          </FormControl>
          <PasswordStrengthBar
            minLength={8}
            password={profileData.password}
            onChangeScore={(score, feedback) => setPasswordScore(score)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              CONFIRM NEW PASSWORD
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPasswordFieldText ? "text" : "password"}
              value={( profileData.confirmPassword ? profileData.confirmPassword : '' )}
              onChange={(e) => handlePassword("confirmPassword",e)}
              error={errorfields.confirmPassword ? errorfields.confirmPassword : false }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setshowPasswordFieldText( !(showPasswordFieldText) );
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordFieldText ? <AbcIcon /> : <PasswordIcon/>}
                  </IconButton>
                </InputAdornment>
              }
              label="CONFIRM NEW PASSWORD"
            />
          </FormControl>
        </Grid>
        </Grid>
         : 
          <Grid container spacing={3} rowSpacing={1} pb={5}>
            <Grid item xs={12}>
              <Button variant="contained" size="small" onClick={()=> { setshowChangePasswordPanel(true) }} endIcon={<LockResetIcon />} >Change My Password</Button>
            </Grid>
          </Grid>
        }
        { (errorfields && errorfields.duplicateemail ? <Alert severity="error">Error : Duplicate email address detected</Alert> : 
            (errorfields && Object.keys(errorfields).length > 0 ? <Alert severity="error">Error : Please fill out required fields</Alert> : <Box sx={{height:'36px'}}/> )
          )
        }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="large" onClick={handleClose} style={{ margin: "10px 0", color: "#005fd4" }}>
            Close
          </Button>
          <div style={{flex: '1 0 0'}} />
          <Button variant="contained" size="large" onClick={() => saveEmployeeFun()} style={{ margin: "10px 0" }}>
            Update Profile
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
