import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ProgressBar from "../../ProgressBar";
import Divider from "@mui/material/Divider";

const TodayProgressCard = ({ status: {available, inProgress, completed } }) => {
  return (
    <Card sx={{ flexBasis: "55%" }}>
      <Typography
        variant="button"
        display="block"
        sx={{ px: 2, py: 1, fontWeight: 700 }}
      >
        today
      </Typography>
      <Divider />
      <CardContent sx={{ display: "flex" }}>
         <ProgressBar
          orderNo={available}
          percent={available}
          orderStatus="available orders"
        />
        <ProgressBar
          orderNo={inProgress}
          percent={inProgress}
          orderStatus="current orders"
        />
        <ProgressBar
          orderNo={completed}
          percent={completed}
          orderStatus="completed orders"
        />
      </CardContent>
    </Card>
  );
};

export default TodayProgressCard;
