import React, { useState,useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import OrderTable from "../OrderTable";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";


const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e5e5e5",
  "& .MuiTabs-indicator": {
    backgroundColor: "primary",
    borderBottom: "1px solid #305DC2",
    borderRadius: "5px",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            pt: 0,
            minHeight: "100vh",
          }}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const OrderTab = ({ declineGuidelines, loading, handleRefreshOrders, order: { availableOrder, currentOrder, completedOrder, operationalReview } }) => {

  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

useEffect(()=>{
  if(!loading && availableOrder?.length > 0 ){
     setValue(0)
  }
},[availableOrder])

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderColor: "transparent",
          mx: 1,
        }}
      >
      {loading && 'loading...'}
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="flex-wrap"
           sx={{
            "& .MuiTab-root": {
              color: "#AFAFAF",
              fontFamily:'Inter,Roboto,"Helvetica Neue",Arial,sans-serif'
            },
          }}
        > 
          <Tab label="available orders" {...a11yProps(0)} sx={{fontWeight: "600"}} />
          <Tab label="current orders" sx={{ mx: 5 ,fontWeight: "600"}} {...a11yProps(1)} />
          <Tab label="completed orders" {...a11yProps(2)} sx={{fontWeight: "600"}}/>
          <Tab label="Operational Review" {...a11yProps(3)} sx={{fontWeight: "600"}}/>
        </AntTabs>
      </Box>
      { loading ? "loading..." :(
         <>
        <TabPanel value={value} index={0}>
          {availableOrder ? (
          <OrderTable
            rows={[...availableOrder]}
            buttonLabel="accept order"
            icon={""}
            status="availableOrder"
            isModalOpen={true}
            refreshMethod={handleRefreshOrders}
            declineGuidelines={declineGuidelines}
          />
        ) : "NOT ready"}
      </TabPanel>
      <TabPanel value={value} index={1}>
         {currentOrder && (
          <OrderTable
            rows={[...currentOrder]}
            buttonLabel="Start Order"
            status=""
            declineGuidelines={declineGuidelines}
            icon={<ArrowRightIcon />}
          />
        )}
      </TabPanel> 
      <TabPanel value={value} index={2}>
          {completedOrder && (
          <OrderTable
            rows={[...completedOrder]}
            buttonLabel="download"
            declineGuidelines={declineGuidelines}
            status="complete"
            icon={<ArrowCircleDownIcon sx={{ fontSize: "1.2rem", mx: 0.5 }} />}
          />
        )}
      </TabPanel>
        <TabPanel value={value} index={3}>
            {operationalReview && (
          <OrderTable
            rows={[...operationalReview]}
            buttonLabel=""
            declineGuidelines={declineGuidelines}
            status="operationalReview"
            icon={<ArrowCircleDownIcon sx={{ fontSize: "1.2rem", mx: 0.5 }} />}
          />
        )}
      </TabPanel>
       </>
       )}
    </Box>
  );
};

export default OrderTab;
