import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function ConfirmDialog({ handleClose, open ,companyName}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        onBackdropClick="false"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ px: 3, pb: 0.5 }}>
          <DialogContentText id="alert-dialog-description">
            We're sorry you  declined our terms. You will continue to have access to this account,
            but  you may be ineligible for the future orders until you have accepted the {companyName} Terms & Conditions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
