import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useDerivedState from '@twipped/hooks/useDerivedState';
import Modal from "../Modal";
import Order from '../models/order';
import getJWT from 'common/authentication/jwt';
import { format } from 'date-fns'
import moment from 'moment';


const SortedTable = ({ rows, declineGuidelines, buttonLabel, icon, status, isModalOpen, refreshMethod }) => {
  const [rowData, setRowData] = useDerivedState(rows, [rows]);
  const [prevRowData, setPrevRowData] = useState(false);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [active, setActive] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadprogress, setDownloadprogress] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [isAvailableOrderRefresh, setIsAvailableOrderRefresh] = useState(false);


  const api = (new Order);
  const getOrderDetails = async (orderId) => {
    try {
      if (orderId) {
        const result = await api.getVendorOrderDetail(orderId);
        if (!result) { setLoading(true) }
        const { status, order } = result;
        if (status === "success") {
          if (order?.orderid === orderId) {
            setOrderDetails(order);
            setLoading(false)
          }
          else {
            setOrderDetails(null);
            setLoading(false)
          }
        }
      }
    }
    catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getDownloadLink = async (partid) => {

    const jwt = getJWT();

    setDownloadprogress(true);

    const result = await api.getPartDownloadLink(partid);

    setDownloadLink(`/grandcentral/vendor/download/${result.uuid}?token=${jwt}`);
  }

  useEffect(() => {
    getOrderDetails()
  }, []);


  const sortItemsAsc = (a, b) => {
    
    let dateA = new Date(a).getTime();
    let dateB = new Date(b).getTime();
    return dateA > dateB ? 1 : -1;
  }

  const sortItemsDesc = (a, b) => {
    var dateA = new Date(a).getTime();
    var dateB = new Date(b).getTime();
    return dateA < dateB ? 1 : -1;
  }

  const sortArray = (arr, orderBy, columnName) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) => {
          if (columnName === "orderDate") {
              sortItemsAsc(a , b );
            var dateA = new Date(a.ordereddate).getTime();
            var dateB = new Date(b.ordereddate).getTime();
            return dateA > dateB ? 1 : -1;
          }
          else if (columnName === "address") {
            // sortItemsAsc(a.address, b.address);
            var addressA = a.address;
            var addressB = b.address;
            return addressA > addressB ? 1 : -1;
          }
          else if (columnName === "orderType") {
            // sortItemsAsc(a.orderType, b.orderType);
            var orderTypeA = a.orderType;
            var orderTypeB = b.orderType;
            return orderTypeA > orderTypeB ? 1 : -1;
          }
          else if (columnName === "dueDate") {
            // sortItemsAsc(a.dueDate, b.dueDate);
            var dueDateA = new Date(a.dueDate).getTime();
            var dueDateB = new Date(b.dueDate).getTime();
            return dueDateA > dueDateB ? 1 : -1;
          }
          else if (columnName === "completeDate") {
            // sortItemsAsc(a.completeDate, b.completeDate);
            var completeDateA = new Date(a.completeDate).getTime();
            var completeDateB = new Date(b.completeDate).getTime();
            return completeDateA > completeDateB ? 1 : -1;  
          }
          else if (columnName === "fee") {
            // sortItemsAsc(a.fee, b.fee);
            var feeA = a.fee;
            var feeB = b.fee;
            return feeA > feeB ? 1 : -1;
          }
          else if (columnName === "reviewDate") {
            // sortItemsAsc(a.reviewDate, b.reviewDate);
            var reviewDateA = a.reviewDate;
            var reviewDateB = b.reviewDate;
            return reviewDateA > reviewDateB ? 1 : -1;
          }
          else if (columnName === "reason") {
            // sortItemsAsc(a.reason, b.reason);
            var reasonA = a.reason;
            var reasonB = b.reason;
            return reasonA > reasonB ? 1 : -1;
          }
          else if (columnName === "timeInReview") {
            // sortItemsAsc(a.timeInReview, b.timeInReview);
            var timeInReviewA = a.timeInReview;
            var timeInReviewB = b.timeInReview;
            return timeInReviewA > timeInReviewB ? 1 : -1;
          }
        });
      case "desc":
        return arr.sort((a, b) => {
          if (columnName === "orderDate") {
            // sortItemsDesc(a?.ordereddate, b?.ordereddate);
            var dateA = new Date(a.ordereddate).getTime();
            var dateB = new Date(b.ordereddate).getTime();
            return dateA < dateB ? 1 : -1;
          }
          else if (columnName === "address") {
            // sortItemsDesc(a.address, b.address);
            var addressA = a.address;
            var addressB = b.address;
            return addressA < addressB ? 1 : -1;
          }
          else if (columnName === "orderType") {
            // sortItemsDesc(a.orderType, b.orderType);
            var orderTypeA = a.orderType;
            var orderTypeB = b.orderType;
            return orderTypeA < orderTypeB ? 1 : -1;
          }
          else if (columnName === "dueDate") {
            // sortItemsDesc(a.dueDate, b.dueDate);
            var dueDateA = new Date(a.dueDate).getTime();
            var dueDateB = new Date(b.dueDate).getTime();
            return dueDateA < dueDateB ? 1 : -1;
          }
          else if (columnName === "completeDate") {
            // sortItemsDesc(a.completeDate, b.completeDate);
            var completeDateA = new Date(a.completeDate).getTime();
            var completeDateB = new Date(b.completeDate).getTime();
            return completeDateA < completeDateB ? 1 : -1;
          }
          else if (columnName === "fee") {
            // sortItemsDesc(a.fee, b.fee);
            var feeA = a.fee;
            var feeB = b.fee;
            return feeA < feeB ? 1 : -1;
          }
          else if (columnName === "reviewDate") {
            // sortItemsDesc(a.reviewDate, b.reviewDate);
            var reviewDateA = a.reviewDate;
            var reviewDateB = b.reviewDate;
            return reviewDateA > reviewDateB ? 1 : -1;
          }
          else if (columnName === "reason") {
            // sortItemsDesc(a.reason, b.reason);
            var reasonA = a.reason;
            var reasonB = b.reason;
            return reasonA > reasonB ? 1 : -1;
          }
          else if (columnName === "timeInReview") {
            // sortItemsDesc(a.timeInReview, b.timeInReview);
            var timeInReviewA = a.timeInReview;
            var timeInReviewB = b.timeInReview;
            return timeInReviewA > timeInReviewB ? 1 : -1;
          }
        });
    }
  };

  const handleSortRequest = (columnName) => {
    setActive(columnName);
    setRowData(sortArray(rows, orderDirection, columnName));
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(isModalOpen);
  };

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (isAvailableOrderRefresh) {
      refreshMethod();
      setIsAvailableOrderRefresh(false);
    }
  }, [isAvailableOrderRefresh]);

  const sortStyles = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTableSortLabel-icon": {
      color: "#1c68c4 !important",
    },
    "&.MuiTableSortLabel-root": {
      color: "#1976D2",
      fontFamily: 'Inter,Roboto,"Helvetica Neue",Arial,sans-serif'
    },
  }
  const borderBottom = {
    borderBottom: "3px solid #1976D2"
  }
  return (
    <>
      <Box
        sx={{
          minHeight: "80vh",
          backgroundColor: "#fff",
          boxShadow: "rgb(0 0 0 / 5%) 0px 1px 1px 0px",
        }}
      >
      
      {!loading && open && (
        <Modal handleClose={handleClose} setIsAvailableOrderRefresh={setIsAvailableOrderRefresh} open={open} orderDetails={orderDetails} />
      )}

      {downloadprogress && (
        <Dialog
          open={downloadprogress}
          onClose={ (event) => {
            setDownloadprogress(false);
            setDownloadLink(false);
          }}
        >
          { (downloadLink && downloadLink != ""  ? <DialogContent sx={{textAlign:"center"}}>Document ready <br /><Button sx={{marginTop:"15px"}} variant="contained" target="_blank" href={downloadLink} endIcon={<FileDownloadIcon />}>Download Now</Button></DialogContent> : <DialogContent sx={{textAlign:"center"}}>Preparing Document <br /><CircularProgress /></DialogContent> ) }
        </Dialog>
      )}

        <TableContainer component={Paper} sx={{ py: 0, boxShadow: "unset" }}>
          <Table aria-label="simple table">
          <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    color: "#1976D2",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                     whiteSpace: "nowrap",
                  },
                }}
              >
                <TableCell
                  onClick={() => handleSortRequest("orderDate")}
                  sx={
                    active === "orderDate"
                      ? borderBottom
                      : null
                  }
                >
                  <TableSortLabel
                    active={active === "orderDate" ? true : false}
                    onClick={() => {
                      setActive(true);
                    }}
                    direction={orderDirection}
                    sx={sortStyles}
                  >
                    order date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="justify"
                  sx={
                    active === "" || active === "address"
                      ? borderBottom
                      : null
                  }
                  onClick={() => handleSortRequest("address")}
                >
                  <TableSortLabel
                    active={
                      active === "" || active === "address" ? true : false
                    }
                    direction={orderDirection}
                    sx={sortStyles}
                  >
                    address
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  onClick={() => handleSortRequest("orderType")}
                  sx={
                    active === "orderType"
                      ? borderBottom
                      : null
                  }
                >
                  <TableSortLabel
                    active={active === "orderType" ? true : false}
                    direction={orderDirection}
                    sx={sortStyles}
                  >
                    order type
                  </TableSortLabel>
                </TableCell>
                
                {status !== "operationalReview" && (
                 <TableCell
                  onClick={() => handleSortRequest("dueDate")}
                  sx={
                    active === "dueDate"
                      ? borderBottom 
                      : null
                  }
                >
                  <TableSortLabel
                    active={active === "dueDate" ? true : false}
                    direction={orderDirection}
                    sx={sortStyles}
                  >
                    due date
                  </TableSortLabel>
                </TableCell>
                )}
               
                {status === "complete" && (
                  <TableCell
                    onClick={() => handleSortRequest("completeDate")}
                    sx={
                      active === "completeDate"
                        ? borderBottom
                        : null
                    }
                  >
                    <TableSortLabel
                      active={active === "completeDate" ? true : false}
                      direction={orderDirection}
                      sx={sortStyles}
                    >
                      Complete date
                    </TableSortLabel>
                  </TableCell>
                )}
                 {status === "operationalReview" && (
                 <>
                    <TableCell
                      onClick={() => handleSortRequest("reviewDate")}
                      sx={
                      
                        active === "reviewDate"
                          ? borderBottom
                          : ""

                                                    ,{ whiteSpace: "nowrap"}
                      }
                    >
                      <TableSortLabel
                        active={active === "reviewDate" ? true : false}
                        direction={orderDirection}
                        sx={sortStyles}
                      >
                        Review date
                      </TableSortLabel>
                    </TableCell>
                     <TableCell
                      onClick={() => handleSortRequest("reason")}
                      sx={
                        active === "reason"
                          ? borderBottom
                          : ""
                      }
                    >
                      <TableSortLabel
                        active={active === "reason" ? true : false}
                        direction={orderDirection}
                        sx={sortStyles}
                      >
                        Reason
                      </TableSortLabel>
                    </TableCell>
                     <TableCell
                      onClick={() => handleSortRequest("timeInReview")}
                      sx={
                        active === "timeInReview"
                          ? borderBottom
                          : ""
                      }
                    >
                      <TableSortLabel
                        active={active === "timeInReview" ? true : false}
                        direction={orderDirection}
                        sx={sortStyles}
                      >
                        Time in review
                      </TableSortLabel>
                    </TableCell>
                  </>
                )}
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData && rowData.length > 0 && rowData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.orderDate && format(new Date(row.orderDate), 'M/dd/yyyy')}
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.orderType}</TableCell>
                 { status !== "operationalReview" && (
                 <>
                 <TableCell>{row.partDueDate && format(new Date(row.partDueDate), 'M/dd/yyyy') + " 5:00 PM"}</TableCell> 
                 </>
                 )} 
                 
                 {status === "complete" && ( <TableCell  align="center">{row.completeDate}</TableCell>)}
                    {status === "operationalReview" && (
                    <> 
                      <TableCell  align="center">{row.reviewDate}</TableCell>
                      <TableCell  align="center">{row.reason}</TableCell>
                      <TableCell  align="center">{row.reviewDate && moment(row.reviewDate,"MM/DD/YYYY HH:mm:ss").fromNow(true)}</TableCell>
                    </>
                    )}
                  <TableCell align="center">
                     {status !== "operationalReview"  && ( 
                       buttonLabel && isModalOpen ? (
                          <Button
                            sx={{whiteSpace:'nowrap'}}
                            variant="outlined"
                            onClick={() => (
                              handleOpen(), getOrderDetails(row?.orderId)
                            )}
                            disabled ={declineGuidelines}
                          >
                            {buttonLabel} {icon}
                          </Button>
                        ) : (
                          ( row.partStatus == 3 && row?.workflowlink !== null ) ?
                          (
                            <Button variant="outlined" target="_blank" href={row?.workflowlink} sx={{whiteSpace:'nowrap'}}>
                              {buttonLabel} {icon}
                            </Button>
                          ) : (
                            <Button variant="outlined" onClick={(event) => {
                              getDownloadLink(row.partId);
                              event.stopPropagation();
                            }}>
                              {buttonLabel} {icon}
                            </Button>
                          )
                        ))}
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length ? rows?.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ display: "flex", justifyContent: "center", my: 2 }}
      />
    </>
  );
};
export default SortedTable;
