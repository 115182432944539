import { useState } from 'react';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EmployeesDialogs from "./addEmpolyeesModal";

import {alpha, css} from "@mui/system";

const PopinModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ModalHeader = styled('div')(({ theme }) => (css`
    background: ${theme.palette.primary.main};
    position: relative;
    color: #fff;
    padding: 30px;
    .user-management-close{
      position: absolute;
      top: 10px;
      right: 10px;
      color: #a3baea;
    }
    .user-management-header{
      font-weight: 400;
      font-size: 28px
    }
    .user-management-subheader{
      color: #a3baea;
    }
  `),
);

const ModalContent = styled('div')(({ theme }) => (css`
    padding: 0.5rem 1.5rem;
    min-height: 500px;
  `),
);

const TableContainerStyled = styled(TableContainer)(css`
  .MuiTableCell-head .MuiSvgIcon-root {
    color: #5a5a5b
  }
`);

const TableHeadStyled = styled(TableHead)(({ theme }) => (css`
    .MuiTableCell-root {
      color: ${theme.palette.primary.main};
      text-transform: uppercase;
      font-size: 12px;
      padding: 12px;
      font-weight: bold;
    }
  `),
);

const TableBodyStyled = styled(TableBody)(css`
  th {
    padding-left: 16px !important;
  }
`);

export default function UserManagementModal ({ show, setShow }) {

  const closeModal = () => {
    setShow(false)
  };

  const employees = [{
    firstname: 'user',
    lastname: '001',
    email: 'test@email.com',
    phone: '12341527',
    role: 'Owner',

  }]

  const roleType = [
    "Owner",
    "Manager",
    "Attorney",
    "Administrator"
  ]
  return (
    <PopinModal
      onClose={closeModal}
      aria-labelledby="dialog-title"
      scroll="paper"
      fullWidth
      maxWidth="lg"
      open={show}
    >
      <ModalHeader>
        <Typography variant="42" component="h2" className="user-management-header">
          User Management
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          className="user-management-close"
          sx={{ color: (theme) => theme.palette.grey[500] }}
        ><CloseIcon /></IconButton>
      </ModalHeader>
      <ModalContent>
        <TableContainerStyled>
          <Table sx={{ minWidth: 650 }} aria-label="">
            <TableHeadStyled>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Manager</TableCell>
                <TableCell></TableCell>
                <TableCell>Order Eligible</TableCell>
              </TableRow>
            </TableHeadStyled>
            <TableBodyStyled>
            {employees.map((row, index) => {
              const handleClick = () => {
                window.open( '/r/admin/vendors?id='+row.userid, '_blank', 'noopener,noreferrer')
              }

              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.firstname}</TableCell>
                  <TableCell>{row.lastname}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <Select
                        name="role_type"
                        value={row.role}
                      >
                        {roleType?.map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      startIcon={<PersonIcon />}
                      onClick={handleClick}
                    >
                      Profile
                    </Button>
                  </TableCell>
                  <TableCell>
                    <CheckCircleRoundedIcon sx={{ width: '20px', height: '20px', fill: '#1de528' }} />
                  </TableCell>
                </TableRow>
              )
              })}
            </TableBodyStyled>
          </Table>
        </TableContainerStyled>
        <EmployeesDialogs/>
      </ModalContent>
    </PopinModal>
  );
};
