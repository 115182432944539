import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

import Loading from 'pages/loading';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DropdownMenu from 'common/ui/DropdownMenu';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

import useToggledState from '@twipped/hooks/useToggledState';
import useAuthentication from 'common/authentication';
import { PipelineServerProvider } from 'common/pipelines/usePipelineServer';
import useCompany from 'common/company';

import Column from 'common/ui/Column';
import Row from 'common/ui/Row';
import GlobalSearch from 'common/ui/GlobalSearch';
import CompanyLogo from 'common/ui/CompanyLogo';
import SidebarContents from './sidebar';
import MainContent from './mainContent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Logo from '../../assets/images/vertoxLogo.png'
// import PostAddIcon from '@mui/icons-material/PostAdd';
import TermsCondition from "common/Terms&Condition";
import Order from 'common/models/order';

import VendorProfile from "./vendorProfile";

import { useVendor } from "core";

const DRAWER_WIDTH = 240;

export default function VendorPages() {
  const { setVendorProfile, getVendorProfile,profileData } = useVendor();
  const {
    state: isDrawerOpen,
    on: openDrawer,
    off: closeDrawer,
    toggle: toggleDrawer,
  } = useToggledState();
  const { logout } = useAuthentication();
  const { companyid ,legalName} = useCompany();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openTermsCondition, setOpenTermsCondition] = useState(false);
  const [termsDetailsResponse, setTermsDetailsResponse] = useState({
    guidelinetext: null,
    id: null
  })
  const open = Boolean(anchorEl);

  const api = (new Order);
  const handleTermsDetails = useCallback(async () => {
    try {
      const result = await api.getTermsDetails();
      if (result && result?.status === "success") {
        if (result?.termsAndCondition?.isAccepted) {
          setOpenTermsCondition(false);
        }
        else {
          const { id, guidelinetext } = result?.termsAndCondition?.guideline;
          setOpenTermsCondition(true);
          setTermsDetailsResponse({
            guidelinetext: guidelinetext,
            id: id
          })
        }
      }
    }
    catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    handleTermsDetails();
    getVendorProfile();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTermsCondition = () => {
    setOpenTermsCondition(!openTermsCondition);
  };


  return (
    <PipelineServerProvider companyid={companyid}>
    {openTermsCondition && (
     <TermsCondition
          open={openTermsCondition}
          handleClose={handleTermsCondition}
          termsDetailsResponse={termsDetailsResponse}
          companyName={legalName}

     />
    )}
      <Row align="stretch" fill sx={{ minHeight: '100vh',p:0 }}>
        <Paper
          elevation={4}
          square
          sx={{
          backgroundColor:'#fff',
          // boxShadow:'rgb(0 0 0 / 5%) 0px 1px 1px 0px',
          boxShadow:'rgb(204 204 204) 2px 0px 2px',
            flexBasis: DRAWER_WIDTH,
            width: DRAWER_WIDTH,
            position:'sticky',
            top:'0%',
            height:'100vh',
            display: { xs: 'none', md: 'block' },
          }}
        >

          <Toolbar sx={{m:0,width:'250px'}}>
           <CompanyLogo />
          </Toolbar>
          <SidebarContents />
        </Paper>
        <Column fill>
          <Toolbar sx={{ px:{md:1,lg:3}}}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{
                mr: { xs: 1, sm: 2 },
                display: { md: 'none' },
              }}
            >
              <MenuIcon />
            </IconButton>
            <GlobalSearch
              sx={{
                flexGrow: 2,
                 maxWidth: { md: '50%' },
                height:40,
                marginLeft:'0.5rem',
                padding:'1.5rem 0'
              }}
            />
            <Box sx={{ flexGrow: { xs: 0, md: 1 },mx:2 }} />
             <Avatar
                alt={profileData.firstname+" "+profileData.lastname}
                src="/static/images/avatar/1.jpg"
                sx={{ width: 50, height: 50, cursor:'pointer'}}
                onClick={handleClick}
              />
              <ArrowDropDownIcon sx={{cursor:'pointer',color:'#B8B7BB'}}  onClick={handleClick}  id="basic-button"/>
             <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
            >
              <MenuItem onClick={() => {setVendorProfile(true)}}>Profile</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            <VendorProfile />
          </Toolbar>
          <Column grow sx={{ px:{md:1,lg:3},mx:1}}>
             <MainContent/>
            <Suspense fallback={<Loading />}><Outlet /></Suspense>
          </Column>
        </Column>

        <SwipeableDrawer
          container={document.body}
          variant="temporary"
          open={isDrawerOpen}
          onOpen={openDrawer}
          onClose={closeDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
        >
          <Toolbar>
            <CompanyLogo />
          </Toolbar>
          <SidebarContents />
          <MainContent/>
        </SwipeableDrawer>
      </Row>
    </PipelineServerProvider>
  );
}
